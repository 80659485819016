@use "./variables" as *;
.mat-mdc-snack-bar-container {
  &.custom-snackbar-panel {
    &.error {
      .mdc-snackbar__surface {
        background-color: darken($error-color, 25%);
      }
    }

    &.instruction {
      .mdc-snackbar__surface {
        background-color: $primary-color;
      }
    }

    &.warning {
      .mdc-snackbar__surface {
        background-color: #ff8c00;
      }
    }

    &.success {
      .mdc-snackbar__surface {
        background-color: #049b07;
      }
    }

    .mdc-snackbar__surface {
      border-radius: $default-border-radius !important;
      padding: 10px !important;

      .mdc-snackbar__label {
        padding: 0 !important;
      }
    }
  }
}
