@use "./variables" as *;

.mat-mdc-table {
  &.default-table {
    background-color: $default-white !important;
    border-radius: 12px !important;
    overflow: hidden !important;
    padding: 5px 20px !important;
    width: 100% !important;
    min-width: 100% !important;
    &.no-padding {
      padding: 0 !important;
    }

    &.with-border {
      border: 1px solid $border-color;
      padding: 10px !important;
    }

    .mat-mdc-header-row {
      height: 46px;
    }

    &.with-paginator {
      border-radius: none !important;
      background: none !important;
    }

    .mat-mdc-row {
      height: 42px;
    }

    .mat-mdc-header-cell {
      font-family: "EnBold", "KhBold";
      color: $primary-color;
      font-size: $default-font-size !important;
      border-bottom-color: rgba(0, 0, 0, 0.03);
    }
    .mat-mdc-header-cell.custom-ng-container-amount {
      width: 10% !important;
    }
    .mat-mdc-footer-cell {
      font-family: "EnBold", "KhBold" !important;
      color: $default-black-color;
      font-weight: bold;
      font-size: $default-font-size !important;
      border-top: 1px solid rgba(0, 0, 0, 0.03);
      padding-bottom: 0;

      &.green {
        color: #20b823 !important;
      }
    }

    .mat-mdc-cell {
      border-bottom-color: rgba(0, 0, 0, 0.03);
      font-family: "EnReg", "KhReg";
      font-size: $default-font-size !important;
    }
    .mat-mdc-cell.custom-font-size {
      font-size: 12px !important;
      padding: 0;
    }

    .mat-cell {
      &.empty {
        text-align: center !important;
        padding: 5px !important;
      }
    }
    &.custom-width {
      width: 150px !important;
    }
    &.no-padding {
      padding: 0 !important;
    }
  }
  &.custom-fixed-deposit-table {
    padding: 0 !important;
    overflow: hidden !important;
  }
}
