@font-face {
  font-family: "KhReg";
  src: url("../assets/fonts/BattambangReg.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "KhBold";
  src: url("../assets/fonts/KhmerOSBattambang-Bold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "KhMoul";
  src: url("../assets/fonts/KhmerOSMoul.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "EnReg";
  src: url("../assets/fonts/Segoe\ UI.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "EnMed";
  font-weight: 500;
  src: url("../assets/fonts/Segoe\ UI.ttf");
}

@font-face {
  font-family: "EnBold";
  src: url("../assets/fonts/Segoe\ UI\ Bold.ttf");
}
