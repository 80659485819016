@use "./custom-theme";
@use "./font";
@use "./variables" as *;
@use "./main-menu";
@use "./input";
@use "./button";
@use "./table";
@use "./card";
@use "./expansion";
@use "./select";
@use "./checkbox";
@use "./menu";
@use "./dialog";
@use "./tabs";
@use "./snackbar";

/* You can add global styles to this file, and also import other style files */
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  max-width: 90vh !important;
  background-color: rgb(229, 57, 53) !important;
}
.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #ffffff !important;
  --mat-text-button-state-layer-color: currentColor;
  --mat-text-button-ripple-color: currentColor;
}
* {
  box-sizing: border-box;
  font-family: "EnReg", "KhReg";
  font-weight: 400;
  letter-spacing: normal;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.page-container {
  width: 100%;
  overflow: hidden;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &.full-height {
    height: 100% !important;
  }

  app-breadcrumb {
    width: auto !important;
  }

  p {
    font-size: $default-font-size;
    margin: 0;
  }

  .container-fluid {
    padding: 0 !important;
    box-sizing: border-box !important;
    .row {
      margin: 0 !important;
      box-sizing: border-box;

      &.with-mb {
        margin-bottom: 10px !important;
      }
    }
  }
}

.mt {
  margin-top: 15px !important;
}

.mr {
  margin-right: 15px;
}

.ml {
  margin-left: 15px;
}

.mb {
  margin-bottom: 15px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  position: relative;

  app-default-card {
    width: 100% !important;
  }
}

app-error-container {
  width: 100%;
}

.full-h {
  height: 100%;
}

@media print {
  .no-print {
    display: none !important;
  }
}
