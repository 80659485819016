@use "./variables" as *;

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(128, 128, 128, 0.185);
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          .mdc-tab__content {
            .mdc-tab__text-label {
              span {
                font-family: "EnMed", "KhBold";
                font-size: $default-font-size !important;
                color: #707070;
              }

              .tab-icon {
                margin-right: 5px !important;
                width: 20px !important;
                height: 20px !important;
                font-size: 20px !important;
              }
            }
          }

          &.mdc-tab--active {
            .mdc-tab__content {
              .mdc-tab__text-label {
                .tab-icon {
                  path {
                    fill: $primary-color !important;
                  }
                }

                span {
                  color: $primary-color !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    margin-top: 12px;
  }
}
