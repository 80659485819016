@use "./variables" as *;

.mat-mdc-form-field {
  font-size: $default-font-size !important;
  &.full-width {
    width: 100% !important;
  }

  &.default-input {
    &.no-outline {
      .mdc-notched-outline {
        display: none !important;
      }
    }

    .mat-mdc-form-field-infix {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      min-height: $input-min-height !important;

      .mat-mdc-input-element {
        line-height: 2 !important;
        color: $default-black-color !important;

        &::placeholder {
          color: lighten($default-gray, 30%) !important;
        }
      }
    }

    .mat-date-range-input-inner {
      &::placeholder {
        color: lighten($default-gray, 30%);
      }
    }

    .mdc-text-field--outlined {
      --mdc-outlined-text-field-container-shape: #{$default-border-radius} !important;
    }

    .mat-datepicker-toggle {
      .mat-mdc-icon-button {
        overflow: hidden !important;
        padding: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }

    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      padding: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      .mat-mdc-icon-button {
        &.close-icon-button {
          background-color: none;
          padding: 0 !important;
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
          .mat-icon {
            padding: 0 !important;
          }
        }
        height: $input-min-height !important;
        width: $input-min-height !important;
        .mat-mdc-button-touch-target {
          height: $input-min-height !important;
          width: $input-min-height !important;
        }
      }

      .mat-icon {
        padding: 0 10px !important;
      }
    }

    .mat-mdc-form-field-text-suffix {
      span {
        font-family: "KhReg", "EnReg" !important;
      }
    }

    .mat-mdc-floating-label {
      top: calc(#{$input-min-height} / 2) !important;
    }

    .mdc-floating-label--float-above {
      transform: translateY(calc(calc(6.75px + #{$input-min-height}/ 2) * -1))
        scale(0.75, 0.75) !important;
    }

    .mat-mdc-form-field-error {
      font-size: 10pt !important;
    }

    .mat-mdc-form-field-hint {
      font-size: 10pt !important;
    }

    &.search {
      width: 200px !important;
    }

    &.no-sub {
      .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
      }
    }

    &.white-bg {
      .mat-mdc-text-field-wrapper {
        background-color: white !important;
        border-radius: $default-border-radius !important;
      }

      .mat-mdc-form-field-icon-suffix {
        color: #b5acc4 !important;

        mat-icon {
          width: 20px !important;
          height: 20px !important;
          font-size: 20px !important;
        }
      }
    }

    &.grey-bg {
      .mat-mdc-text-field-wrapper {
        background-color: rgb(236, 236, 236) !important;
        border-radius: $default-border-radius !important;
      }

      .mat-mdc-form-field-icon-suffix {
        color: #b5acc4 !important;

        mat-icon {
          width: 20px !important;
          height: 20px !important;
          font-size: 20px !important;
        }
      }
    }

    &.no-marign {
      margin: 0 !important;
    }

    &.shrink {
      .mat-mdc-form-field-infix {
        width: 120px !important;
      }
    }
  }
  &.custom-input {
    width: 120px !important;
    border-radius: 20px !important;
  }
}
