@use "../styles/variables" as *;

.default-card {
  width: 100%;
  height: auto;
  border-radius: $default-border-radius;
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  &.blank {
    padding: 0 !important;
  }

  &.dangerous {
    border: 1px solid $red-color;

    .title-row {
      mat-icon {
        color: $error-color;
      }

      .title {
        color: $error-color;
      }
    }
  }

  &.full-height {
    height: 100% !important;

    .body {
      height: 100% !important;
    }
  }

  .title-row {
    width: 100%;
    margin-bottom: 10px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: $primary-color;

    .spacer {
      flex: 1;
    }

    mat-icon {
      font-size: 20px;
    }

    .title {
      font-size: $card-title-font-size;
      font-family: "EnReg", "KhBold";
      margin: 0;
    }
  }

  .body {
    width: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box !important;

    .row {
      width: 100% !important;
    }

    .label {
      margin: 0 0 5px 0;
      color: $label-color;
      font-size: $default-font-size;
    }

    .content {
      margin: 0 0 15px 0;
      font-size: $default-font-size;
      font-family: "EnMed", "KhReg";
      line-height: 2;
      color: $primary-color;
    }
  }
}
.custom-title-row {
  margin-bottom: 0;
}
