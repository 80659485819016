@use "./variables" as *;
.mat-mdc-select {
  .mat-mdc-select-value-text {
    font-size: $default-font-size !important;
  }

  .mat-mdc-select-placeholder {
    font-size: $default-font-size !important;
  }

  &.filter {
    padding: 2px 0 !important;
  }

  &.custom-select {
    .mat-mdc-select-placeholder {
      font-size: $default-font-size !important;
      line-height: 2 !important;
      color: lighten($default-gray, 30%) !important;
    }
    .mat-mdc-select-value-text {
      font-size: $default-font-size !important;
      line-height: 2 !important;

      mat-select-trigger {
        .bigger-font {
          margin: 0 !important;
          font-size: 14px !important;
          line-height: 1.5 !important;
        }
      }
    }
  }
  &.custom-select-bank {
    width: 200px !important;
  }
}

.mat-mdc-option {
  &.custom-option {
    .mdc-list-item__primary-text {
      font-family: "EnReg", "KhReg" !important;
      font-size: $default-font-size !important;
    }
  }
}
