@use "./variables" as *;

.custom-dialog {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      border-radius: 15px !important;

      .dialog-container {
        width: 100%;
        padding: 25px;

        .mat-mdc-dialog-title {
          margin: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          &::before {
            display: none !important;
          }

          &.title {
            font-family: "EnMed", "KhBold" !important;
            color: $primary-color !important;
            font-weight: 400 !important;
            letter-spacing: normal !important;
          }
        }

        .mat-mdc-dialog-content {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 10px !important;

          form {
            width: 100%;
            height: auto;
            display: block !important;
            position: relative !important;
            box-sizing: border-box !important;
            overflow-x: hidden !important;
          }
        }

        .mat-mdc-dialog-actions {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
