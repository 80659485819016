@use "./variables" as *;

.main-menu {
  width: 100%;

  .mdc-list-item {
    border-radius: $default-border-radius;
    padding: 0px 10px !important;
    cursor: pointer !important;

    &.active-route {
      background-color: rgba(#ffffff, 0.2);

      &::before {
        background-color: rgba(#ffffff, 0.2) !important;
      }
    }

    .mdc-list-item__primary-text {
      color: white !important;
      font-size: $default-font-size;
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      column-gap: 5px;
      //text-wrap: wrap !important;

      mat-icon {
        height: 24px !important;
        width: 20px !important;
        font-size: 20px !important;
      }
    }
  }

  .mat-expansion-panel {
    box-shadow: none !important;
    background: none !important;
    border-radius: $default-border-radius !important;

    .mat-expansion-panel-header {
      padding: 0 10px !important;
      height: 44px !important;

      mat-icon {
        height: 24px !important;
        width: 20px !important;
        font-size: 20px !important;
      }

      &.mat-expanded {
        height: 44px !important;
      }

      .mat-expansion-panel-header-title {
        color: white !important;
        font-size: $default-font-size;
        gap: 5px;
      }

      .mat-expansion-indicator {
        &::after {
          color: white !important;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0px !important;

      .mdc-list-item {
        padding-left: 40px !important;
        padding-right: 0 !important;
        border-radius: $default-border-radius;
        cursor: pointer;

        &.active-route {
          background-color: rgba(#ffffff, 0.2);

          &::before {
            background-color: rgba(#ffffff, 0.2) !important;
          }
        }

        .mdc-list-item__primary-text {
          color: white !important;
          font-size: $default-font-size;
        }
      }
    }
  }
}
